
import { Grid, Paper, List, ListItem } from '@mui/material';
const paperStyle = { padding: 20, height: '40vh', width: 800, margin: "20px auto" }

export const About = () => {
  return (
    <>

      <Grid>
        <Paper elevation={10} style={paperStyle}>
          <Grid align='center'>
            <h2>About</h2>
          </Grid>
          This app is used to lookup a clinical trial in the AACT and the DRE database using these 5 features.



          <List>
            <ListItem >
              1. Condition Term: Required
            </ListItem>
            <ListItem >
              2. Intervention Term: Required
            </ListItem>
            <ListItem >
              3. Outcome Term: Not Required
            </ListItem>
            <ListItem >
              4. Enroll N: Not Required. The 
            </ListItem>
            <ListItem >
              5. Groups N: Not Required. Enter the size of groups with a ', ' seperating them.
            </ListItem>
          </List>


        </Paper>
      </Grid>


    </>
  );
};