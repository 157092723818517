import React from 'react'
import { Outlet } from 'react-router-dom';
// import { useState } from 'react';
import { Box } from '@mui/system';


import { styled} from '@mui/material/styles';


import Header from './HeaderComps/Header';

import { useData } from '../hooks/useData';
import { Loading } from '../pages/Loading';
import { Footer } from '../pages/Footer';




const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    backgroundColor: theme.palette.primary.Warm_White,
    
    minHeight: '100vh',
    height: '100%',
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export const Layout = () => {
    const {loading} = useData();
    // const theme = customTheme;
    // const [open, setOpen] = useState(false);
    const open = true;
    const [anchorEl, setAnchorEl] = React.useState(null);

    // const handleDrawerOpen = () => {
    //   setOpen(true);
    // };
    // const handleDrawerClose = () => {
    //   setOpen(false);
    // };

    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

  
    return (
 <Box sx={{ 
      display: 'flex' 
      }}> 
      {/* Handle the width of the navbar when opened or closed*/}  
      
      <Header 
      // open = {open}
      // handleDrawerOpen = {handleDrawerOpen}
      handleClose = {handleClose}
      anchorEl = {anchorEl}
      handleMenu = {handleMenu}
      /> 


      {/* Handle what is in the sidebar in it's own component*/}      
      {/* <Sidebar
        open={open} 
        theme = {theme}
        handleDrawerClose = {handleDrawerClose}
      />  */}


    <Main open={open}>
    <DrawerHeader/>   
    {loading? <Loading/> : <Outlet />}       

    </Main> 
    <Footer/>
    </Box>



    );
  };
