
import { Routes, 
  Route} from 'react-router-dom';
import React from 'react';

// context and auth

import { RequireAuth } from './components/RequireAuth';

//pages
import { Layout } from './components/Layout';
import { About } from './pages/About';


import { Login } from './pages/Login';
import { Lookup } from './pages/Lookup';



  const App = () => {





    return (
        <Routes>
        <Route element={<Layout />}>
          <Route path="login" element={<Login />} />
          <Route path="about" element={<About/>} />
          {/* <Route path="test" element={<Test />} /> */}
          <Route element = {<RequireAuth/>}> 
          <Route index element={<Lookup />} />
            <Route path="private" element={<Private/>} />
            
          </Route>  
          <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>

    );
  };



const Private = () => {
  return (
    <>
      <h2>Private</h2>
    </>
  );
};
const NoMatch = () => {
  return (
    <>
      <h2>NoMatch</h2>
    </>
  );
};



export default App;




