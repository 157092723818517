import React from 'react'
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import { useAuth } from '../../hooks/useAuth';
export const ProfileButton = (props) => {
    const { onLogout } = useAuth();

    const handleLogout = () => {
        onLogout();
        props.handleClose();
      };

  return (
    <div>
    <IconButton
      size="large"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={props.handleMenu}
      color="inherit"
    >
      <AccountCircle />
    </IconButton>
    <Menu
    id="menu-appbar"
    anchorEl={props.anchorEl}
    anchorOrigin={{
    vertical: 'top',
    horizontal: 'right',
    }}
    keepMounted
    transformOrigin={{
    vertical: 'top',
    horizontal: 'right',
    }}
    open={Boolean(props.anchorEl)}
    onClose={props.handleClose}
>
    <MenuItem onClick={props.handleClose}>Profile</MenuItem>
    <MenuItem onClick={props.handleClose}>My account</MenuItem>
    <MenuItem onClick={handleLogout}> Logout</MenuItem>

</Menu>
  </div>
  )
}
