
  import React from 'react';
  import { useState } from 'react';
  // import { useEffect } from 'react';
  import { useNavigate } from "react-router-dom";


export const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {

  const navigate = useNavigate();
  
  const [token, setToken] = useState('');
  const [auth, setAuth]  = useState(false)
  const [user, setUser] = useState('')
  

  const [login, setLogin]  = useState({
    username: "username", 
    password: "password"
  }) 

  function handleLoginInput(evt) {
    const value = evt.target.value;
    setLogin({
      ...login,
      [evt.target.name]: value
    });
  }

async function submitLogin() {


await fetch('https://luna.doctorevidence.com/api/federate/login',{
  method: 'POST', 
  credentials: 'include',
  headers: {
    "Content-Type": "application/json"
  },
  body: JSON.stringify(login)
}).then(response =>{ 
  if(response.status!==200){
    throw new Error(response.status)
  }else{
    for (var pair of response.headers.entries()) {
      console.log(pair[0]+ ': '+ pair[1]);
   }

    response.json()   
  }
  })
.then(json =>{
  console.log(json)
  setUser(login.username)
  setAuth(true)
  getBToken()
  localStorage.setItem('user', login.username)
})
.catch(function() {
  console.log("Sign in error");
  setUser('')
  setAuth(false)
  localStorage.clear();
});    
  navigate("/");

}


 async function confirmCookie() {
    await fetch('https://luna.doctorevidence.com:3443/api/user/currentuser?Select=UserName,Client,ImpersonateUser&format=json',{
      method: 'GET',
      credentials: 'include'
    }).then(response => {
      for (var pair of response.headers.entries()) {
        console.log(pair[0]+ ': '+ pair[1]);
     }
     response.json()
    }
      )
    .then(json =>{
      console.log(json)
      setUser(json.UserName)
      setAuth(true)
    })
    .catch(function() {
      console.log("Cookie error");
      setUser('')
      setAuth(false)
      localStorage.clear();
  });
  navigate("/");
    


 

  }





  async function getBToken() {


    fetch('https://federate.doctorevidence.com/api/keys/reset?format=json', {
      credentials: 'include'
    })
  .then((response) => response.json())
  .then((data) => {
    // console.log(data)
    setToken(data.Key)
  
  });
    

 

  }





  const handleLogout = () => {
    setToken(null);
    setUser('')
    setAuth(false)
    localStorage.clear();
    navigate("/");
  };

   // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  // https://usehooks.com/useAuth/
  
  // useEffect(() => {
  //   confirmCookie()
  //   });


    // useEffect(() => {
    //   const loggedInUser = localStorage.getItem("user");
    //   if (loggedInUser) {
    //     console.log('cookie')
    //     confirmCookie()
      
    //   }else{
    //     console.log('no cookie')    
    //   }
    // }, []);


  const value = {
    user, 
    auth,
    token,
    login, 
    submitLogin,
    confirmCookie,
    getBToken,
    loginChange: handleLoginInput,
    onLogout: handleLogout,
  };



  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};


