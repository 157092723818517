import React from 'react'
import { Typography } from '@mui/material';
import {Link} from '@mui/material';
import {Paper} from '@mui/material';

export const Footer = () => {
    return (

<Paper sx={{marginTop: 'calc(10% + 60px)',
    width: '100%',
    position: 'fixed',
    bottom: 0
    }} component="footer" square variant="outlined">

      



        <Typography variant="body2" color="text.secondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://www.drevidence.com/">
            DoctorEvidence
          </Link>{' '}
          {new Date().getFullYear()}.
        </Typography>
        </Paper>

      );
}
