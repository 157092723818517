import React from 'react'
import {Box, Button} from '@mui/material'
import { useData } from '../hooks/useData';
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';

export const SubmitDownload = () => {
const { session_id, onSubmit, onDownload, respStatus, loading} = useData();
const buttonSx = {
    width: '100%',
    ...(respStatus && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  return (

<Box> 


  <Box sx={{  position: 'relative' }}>
  <Button
    sx={buttonSx}
    onClick = {onSubmit}
    disabled={loading}
     variant="contained">
      Submit
    </Button>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
      <Button variant="contained" 
    disabled = {session_id === "nosession"}
    onClick = {onDownload}>
      Download Report
    </Button>

    </Box>

  )
}
