import React from 'react'
import { useAuth } from '../hooks/useAuth';




import { Grid,Paper, Box, TextField, Button} from '@mui/material';

export const Login = () => {

  
    const {loginChange, submitLogin, getBToken} = useAuth();
    const paperStyle={padding :20, height:'30vh' ,width:300, margin:"20px auto"}
    const btnstyle={margin:'8px 0'}
    const textStyle = {
      mt: 2
    };








    return (
      <>
        <Grid>
            <Paper elevation={10} style={paperStyle}>
                <Grid align='center'>
                    <h2>Sign In</h2>
                </Grid>

                <Box      
          sx={{
            margin:"20px auto"
        }}>
                <form>
                <TextField sx = {{...textStyle}} name="username" 
                 label='Username'  
                 fullWidth required 
                  onChange={loginChange}/>
                <TextField sx = {{...textStyle}} label='Password' name="password" type='password'  autoComplete="on" fullWidth required onChange={loginChange}/>
                </form>
                </Box>
                <Button type='submit' color='primary' variant="contained" style={btnstyle} fullWidth onClick={submitLogin}>Sign in</Button>
                {/* <Typography >
                     <Link href="#" >
                        Forgot password ?
                </Link>
                </Typography>
                <Typography > Do you have an account ?
                     <Link href="#" >
                        Sign Up 
                </Link>
                </Typography> */}
            </Paper>
        </Grid>

    {/* <form>
      <label>
  
        <textarea name="username" value={login.username} onChange={loginChange} />
        <textarea name="password" value={login.password} onChange={loginChange} />
      </label>
    </form>
    <Button onClick={submitLogin} variant="contained"> 
        Sign In as User
    </Button> */}



        {/* <h2>Home (Public)</h2>
        <Button onClick={() => onLogin("User")} variant="contained"> 
        Sign In as User
        </Button>
        <Button onClick={() => onLogin("Test Developer")} variant="contained"> 
        Sign In as Developer
        </Button>
        <Button onClick={() => onLogin("Demo")} variant="contained"> 
        See the Demo version. 
        </Button> */}
      </>
    );
}
