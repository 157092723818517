import React from 'react'

import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MuiAppBar from '@mui/material/AppBar';




//hooks
import { useAuth } from '../../hooks/useAuth';
import { useData } from '../../hooks/useData';
import { Box } from '@mui/system';
import { ProfileButton } from './ProfileButton';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({


  height: '100px',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


export default function Header(props) {
  const { auth, user, token } = useAuth();
  const { session_id } = useData();

  return (
    <Box sx={{ display: 'flex'  }} align='center'>
      <AppBar
        position="fixed"
        open={props.open}
        component="nav">
        <Toolbar>
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(props.open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton> */}

          <Typography noWrap variant="h1" component="div" sx={{ flexGrow: 1 }}>
            Search Group Report
          </Typography>
          {user === 'lduarte@doctorevidence.com' &&
            <Typography noWrap variant="h8" component="div" sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}>
              <div>Authenticated as : {user}  </div>
              <div>Session_id : {session_id}  </div>
              <div>BToken : {token}  </div>
            </Typography>
          }

          {auth && (
            <>
              <ProfileButton
                handleClose={props.handleClose}
                anchorEl={props.anchorEl}
                handleMenu={props.handleMenu} 
                />
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  )
}
