import React from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useData } from '../hooks/useData';
import {useAuth}from '../hooks/useAuth';
// or
import { Button, FormControl } from '@mui/material';
import { SubmitDownload } from '../components/SubmitDownload';
// import { useCookies } from 'react-cookie';




export const Lookup = () => {
  // const [cookies, setCookie] = useCookies(['name']);
  const {getBToken} = useAuth();
  const {query, query_set} = useData();
  const {Studies_N, Studies_N_set} = useData();



 const clearCurrentValues = (event,) => {
  query_set('')
  Studies_N_set('')
  }
  const setDemoValues = () => {

    query_set('k(SUO3iP.Stoke%20Type)')
    Studies_N_set('25')

    }

  const handleTextChange = (event, param) => {

    param(event.target.value)
    // console.log(event.target.value)
    // console.log(condition_term)

  };

  const style = {
    mt: 2,
    ml: 2,
    width: { sm: 300, md: 600 },
    boxShadow: 6,
    "& .MuiFilledInput-input": { color: "white" }
  };

  return (
    <Box sx = {{
      display: 'flex',
      flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent:"center",
    alignItems:"center"
      }}>


    <Box sx = {{
    boxShadow: 1,
    borderRadius: 2,
    backgroundColor: 'white',
    padding: 2, 
    margin: 2,
    minWidth: 400,
    maxWidth: 1200
    }}>
    <FormControl
    sx = {{  
      display: 'flex',
    flexDirection: 'col',
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent:"center",
    alignItems:"center"
  }}
    >

<TextField 
    sx = {{...style}}
    onChange = {event => handleTextChange(event, query_set)}
    label = "query" 
    value = {query} 
    /> 


    <TextField
    sx = {{...style}}
            onChange = {event => handleTextChange(event, Studies_N_set)}
             type="number"
              name="N Studies"
              label = "N Studies"
              value = {Studies_N}
            />

</FormControl>
</Box>

<SubmitDownload/>
<Box sx={{  position: 'relative' }}>
  

<Button variant="contained" 
    onClick = {event => clearCurrentValues(event)}>
      Clear  
    </Button>

    <Button variant="contained" 
    onClick =  {setDemoValues}>
     Default Values 
    </Button>
    <Button variant="contained" 
    onClick =  {getBToken}>
     BToken
    </Button>

    </Box>



{/* <Box>
<List sx = {{
    backgroundColor: 'rebeccapurple',
    color: 'white',
    boxShadow: '5px 10px #888888'

    }}> 
      <ListItem component="h1" disablePadding>
      Condition Term: {condition_term}
      </ListItem>
      <ListItem component="h1"  disablePadding>
      Intervention Term: {intervention_term}
      </ListItem>
      <ListItem component="h1"  disablePadding>
      Outcome Term: {outcome_term}
      </ListItem>
      <ListItem component="h1"  disablePadding>
      Enroll_N: {Enroll_N}
      </ListItem>
      <ListItem component="h1" disablePadding>
      Groups_N: {groups_N}
      </ListItem>
    </List>


</Box> */}

</Box>

  )
}
