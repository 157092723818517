
import React from 'react';
import { useState } from 'react';
import { useAuth } from '../hooks/useAuth';


export const DataContext = React.createContext(null);

export const DataProvider = ({ children }) => {
    const url_create_data_root = 'https://test-app.doctorevidence.com'
    const main_package = 'authorReport'
    const main_function = 'createReport'
    const url_openCPU = 'https://test-app.doctorevidence.com/ocpu/tmp/'

    const [loading, setLoading] = React.useState(false);
    const [session_id, setSession] = useState('nosession');

    const {token} = useAuth();
    
    const timer = React.useRef();
    
    const session_dict = 
    {
      session_id, setSession, loading, setLoading
    }
    /*
    The setters are stored here. Each one should correspond to an R file made by openCPU. 
    */ 

    const [query, query_set] = useState('k(SUO3iP.Stoke%20Type)');
    const [Studies_N, Studies_N_set] = useState(25);



    const input_dict = 
    {
      query, query_set,
      Studies_N, Studies_N_set
    } 

    /** This pushes a default payload to R on openCPU and retrieves the session and stores it.
     *  We can then check the openCPU temp session for the created data payloads. 
     * The function is asynronous since we want to wait for the R function to finish running before trying to get the session 
     * or the data files created in the session. 
     * @param {string} push_package name of the function in the default package we push a payload to generate data. 
     * @returns 
     */



    async function getSes(push_package) {    
    const url_create_data = `${url_create_data_root}/ocpu/library/${main_package}/R/${push_package}`
    console.log(url_create_data)

    return await fetch(url_create_data, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'query': query,
          'Studies_N': Studies_N, 
          'BToken': token,
        })
      })    
      .then(response => {
        setSession(response.headers.get('X-ocpu-session'))
        console.log(`${url_openCPU}${response.headers.get('X-ocpu-session')}/files`)
       return response.headers.get('X-ocpu-session')
      }
      )
      .catch(err => window.alert(err))  
      
      
   }
    

    /**
     * Makes a request to openCPU for some random data. 
     * Currently does not provide any data to openCPU as a a payload. 
     * @param {string} push_package - The name of the R package to use to get data. 
     *  
     */

   async function reportTemplate(push_package){
    setLoading(true);
    // const my_session = await getSes(push_package);  // command waits until completion 
    await getSes(push_package);
    // Only parse file if done. 
    setLoading(false);
      console.log('Session created')
    // getOutputs for reacts
    //   for (let k in openCPUoutputMatch) {
    //     console.log(k)
    //     await getPayload(k, my_session, openCPUoutputMatch[k])        
    // }
    
  }


 
    // Download the report. 

    async function mainReport() {
      console.log('Getting Report')
      await reportTemplate(main_function)
    }
    const downloadOutput = e => {
      e.preventDefault()
      var url_fetch_data = `${url_openCPU}${session_id}/files/report.html`
      fetch(url_fetch_data, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        },
      })
      .then((response) => response.blob()).then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'report.html';
        a.click();
      });
    }
    const simLoad = () => {
      if (!loading) {
  
        setLoading(true);
        timer.current = window.setTimeout(() => {

          setLoading(false);
        }, 2000);
      }
    };

    const function_dict = {
      onLoad: simLoad,
      onSubmit: mainReport,
      onDownload: downloadOutput,
      
    }



  // These are the function I pass to the context handler.  


  var value = {...session_dict , ...input_dict, ...function_dict}
  return (
    <DataContext.Provider value={value}>
      {children}
    </DataContext.Provider>
  );
};


