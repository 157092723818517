import React from 'react'
import { CircularProgress } from '@mui/material'
import {Grid} from '@mui/material'

export const Loading = () => {
  return (
    <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  justifyContent="center"
  style={{ minHeight: '100vh' }}
>

  <Grid item xs={3}>
    <CircularProgress size='70vh' />: 
    </Grid>   
   
   </Grid> 
  )
}
